/* 
- This Code Designed by Sinaha Team 2024
- This Portrait Catalog Cover Design (Template-1)
*/
body,
html {
  font-variation-settings: "slnt" 0;
  font-family: "Cairo", sans-serif;
  font-optical-sizing: auto;
}

.page-style-cover {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.page-style-cover.customize {
  position: unset;
}

.customize .page-style-cover {
  overflow: auto;
}

.catelog-cover {
  background-color: #fff;
  text-align: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.catalog-cover-border {
  background-color: transparent;
  position: absolute;
  z-index: 999999;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}

.customerLogo {
  width: 100%;
}

.Portrait.temp-1 .coverImage {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  position: absolute;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
  height: 60%;
  opacity: 1;
  z-index: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.Portrait.temp-1 .coverImage .overlay {
  background: -webkit-gradient(linear, left bottom, left top, from(#000000), to(rgba(0, 0, 0, 0.5019607843)));
  background: linear-gradient(0deg, #000000, rgba(0, 0, 0, 0.5019607843));
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}
.Portrait.temp-1 .rectangle-center-inside {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 85%;
  width: 85%;
}
.Portrait.temp-1 .rectangle-center-company-logo {
  background-color: #fff;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  height: 80%;
  width: 80%;
}
.Portrait.temp-1 .rectangle-center-company-logo img {
  -webkit-transform: rotate(316deg);
          transform: rotate(316deg);
  -o-object-fit: contain;
     object-fit: contain;
  position: absolute;
  margin: auto;
  width: 75%;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}
.Portrait.temp-1 .year {
  position: absolute;
  margin-right: auto;
  margin-left: auto;
  z-index: 9999;
  width: 100%;
  bottom: 2%;
  right: 0;
  left: 0;
}
.Portrait.temp-1 .year p {
  font-size: 14px;
  margin: 0;
  color: #fff;
}
.Portrait.temp-1 .year::before {
  top: 50%;
  left: 10%;
  width: 30%;
  content: "";
  position: absolute;
  border: 1px solid #fff;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.Portrait.temp-1 .year::after {
  top: 50%;
  width: 30%;
  right: 10%;
  content: "";
  position: absolute;
  border: 1px solid #fff;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.Portrait.temp-1 .cover-qr-code {
  position: absolute;
  height: auto;
  width: 15%;
  z-index: 9;
  right: 2%;
  top: 2%;
}
.Portrait.temp-1 .copyright-text {
  -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
  text-orientation: mixed;
  letter-spacing: 2px;
  position: absolute;
  font-size: 0.5em;
  bottom: unset;
  z-index: 999;
  color: #fff;
  left: 1%;
  top: 13%;
}
.Portrait.temp-1 .title {
  position: absolute;
  margin: auto;
  left: unset;
  color: #fff;
  z-index: 99;
  bottom: 10%;
}
.Portrait.temp-1 .title h2 {
  font-weight: 800;
  margin: 0;
}
.Portrait.temp-1 .title h4 {
  letter-spacing: 7px;
  margin: 0;
}
.Portrait.temp-1 .coverImage img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}
.Portrait.temp-1 .rectangle-left {
  border-radius: 0px 20px 0 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-filter: brightness(85%);
          filter: brightness(85%);
  position: absolute;
  height: 50%;
  left: -40%;
  width: 70%;
  z-index: 9;
  top: 7%;
}
.Portrait.temp-1 .rectangle-right {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-filter: brightness(85%);
          filter: brightness(85%);
  position: absolute;
  right: -50%;
  height: 50%;
  width: 70%;
  z-index: 9;
  top: 14%;
}
.Portrait.temp-1 .rectangle-center {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  background-color: #fff;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 20px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  margin-right: auto;
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 99;
  height: 35%;
  width: 50%;
  right: 0;
  top: 15%;
  left: 0;
}
.Portrait.temp-1 .company-name {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
  z-index: 99;
  width: 75%;
  top: 60%;
  right: 0;
  left: 0;
}
.Portrait.temp-1 .company-name h4 {
  font-weight: 800;
  font-size: 25px;
  color: #fff;
  margin: 0;
}
.Portrait.temp-1 .title {
  right: 0;
  left: 0;
}
.Portrait.temp-1 .title h2 {
  font-size: 4em;
}
.Portrait.temp-1 .title h4 {
  font-size: 1.8em;
}
.Portrait.temp-1 .turn-ar .title h2 {
  font-size: 3em;
}
.Portrait.temp-1 .turn-ar .title h4 {
  letter-spacing: 0;
  font-size: 14px;
}
@media only screen and (max-width: 768px) {
  .Portrait.temp-1 .rectangle-center {
    width: 45%;
  }
  .Portrait.temp-1 .company-name h4 {
    font-size: 14px;
  }
  .Portrait.temp-1 .title h2 {
    font-size: 2em;
  }
  .Portrait.temp-1 .title h4 {
    font-size: 0.5em;
  }
  .Portrait.temp-1 .turn-ar .title h2 {
    font-size: 2em;
  }
  .Portrait.temp-1 .copyright-text {
    font-size: 0.4em;
  }
  .Portrait.temp-1 .year p {
    font-size: 10px;
  }
}

.Landscape.temp-1 .coverImage {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  position: absolute;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
  height: 60%;
  opacity: 1;
  z-index: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.Landscape.temp-1 .coverImage .overlay {
  background: -webkit-gradient(linear, left bottom, left top, from(#000000), to(rgba(0, 0, 0, 0.5019607843)));
  background: linear-gradient(0deg, #000000, rgba(0, 0, 0, 0.5019607843));
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}
.Landscape.temp-1 .rectangle-center-inside {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 85%;
  width: 85%;
}
.Landscape.temp-1 .rectangle-center-company-logo {
  background-color: #fff;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  height: 80%;
  width: 80%;
}
.Landscape.temp-1 .rectangle-center-company-logo img {
  -webkit-transform: rotate(316deg);
          transform: rotate(316deg);
  position: absolute;
  margin: auto;
  width: 75%;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}
.Landscape.temp-1 .year {
  position: absolute;
  margin-right: auto;
  margin-left: auto;
  z-index: 9999;
  width: 100%;
  bottom: 2%;
  right: 0;
  left: 0;
}
.Landscape.temp-1 .year p {
  font-size: 14px;
  margin: 0;
  color: #fff;
}
.Landscape.temp-1 .year::before {
  top: 50%;
  left: 10%;
  width: 30%;
  content: "";
  position: absolute;
  border: 1px solid #fff;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.Landscape.temp-1 .year::after {
  top: 50%;
  width: 30%;
  right: 10%;
  content: "";
  position: absolute;
  border: 1px solid #fff;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.Landscape.temp-1 .cover-qr-code {
  position: absolute;
  height: auto;
  width: 10%;
  z-index: 9;
  right: 2%;
  top: 2%;
}
.Landscape.temp-1 .copyright-text {
  -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
  text-orientation: mixed;
  letter-spacing: 2px;
  position: absolute;
  font-size: 0.5em;
  bottom: unset;
  z-index: 999;
  color: #fff;
  left: 1%;
  top: 13%;
}
.Landscape.temp-1 .title {
  position: absolute;
  margin: auto;
  left: unset;
  color: #fff;
  z-index: 99;
  bottom: 10%;
}
.Landscape.temp-1 .title h2 {
  font-weight: 800;
  margin: 0;
}
.Landscape.temp-1 .title h4 {
  letter-spacing: 7px;
  margin: 0;
}
.Landscape.temp-1 .rectangle-left {
  border-radius: 0px 20px 0 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-filter: brightness(85%);
          filter: brightness(85%);
  position: absolute;
  height: 100%;
  left: -40%;
  width: 65%;
  z-index: 9;
  top: -25%;
}
.Landscape.temp-1 .rectangle-center {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  background-color: #fff;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 20px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  margin-right: auto;
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 99;
  height: 55%;
  width: 36%;
  top: 9%;
  left: 15%;
}
.Landscape.temp-1 .rectangle-right {
  position: absolute;
  right: -22%;
  top: 3%;
  z-index: 9;
  --r: 75px;
  width: 85%;
  aspect-ratio: 1;
  -o-object-fit: cover;
     object-fit: cover;
  margin: calc(0.4142135624 * var(--r));
  -webkit-clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%) margin-box;
          clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%) margin-box;
  --_g: /calc(2 * var(--r)) calc(2 * var(--r)) radial-gradient(#000 70%, #0000
        72%);
  --_s: calc(100% - (1 - tan(22.5deg)) * var(--r));
  -webkit-mask: conic-gradient(#000 0 0) no-repeat 50%/var(--_s) var(--_s), top var(--_g) no-repeat space, left var(--_g) space no-repeat;
          mask: conic-gradient(#000 0 0) no-repeat 50%/var(--_s) var(--_s), top var(--_g) no-repeat space, left var(--_g) space no-repeat;
}
.Landscape.temp-1 .company-name {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
  z-index: 99;
  width: 50%;
  top: 57%;
  right: 3%;
}
.Landscape.temp-1 .company-name h4 {
  font-weight: 800;
  font-size: 35px;
  color: #fff;
  margin: 0;
}
.Landscape.temp-1 .title {
  bottom: 10%;
  left: 9%;
}
.Landscape.temp-1 .title h2 {
  font-size: 2.5em;
}
.Landscape.temp-1 .title h4 {
  font-size: 0.8em;
}
.Landscape.temp-1 .turn-ar .title {
  left: 5%;
}
.Landscape.temp-1 .turn-ar .title h2 {
  font-size: 2em;
}
.Landscape.temp-1 .turn-ar .title h4 {
  letter-spacing: 0;
  font-size: 14px;
}

@media only screen and (max-width: 768px) {
  .homeDiv .Portrait.temp-1 .company-name h4,
  .allCatalogDiv .Portrait.temp-1 .company-name h4 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 768px) {
  .homeDiv .Landscape.temp-1 .company-name h4,
  .allCatalogDiv .Landscape.temp-1 .company-name h4 {
    font-size: 25px;
  }
}

/* 
- This Code Designed by Sinaha Team 2024
- This Portrait Catalog Cover Design (Template-2)
*/
.Portrait.temp-2 .year {
  position: absolute;
  margin-right: auto;
  margin-left: auto;
  z-index: 9999;
  width: 100%;
  bottom: 2%;
  right: 0;
  left: 0;
}
.Portrait.temp-2 .year p {
  font-size: 14px;
  color: #fff;
  margin: 0;
}
.Portrait.temp-2 .year::before {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border: 1px solid #fff;
  position: absolute;
  content: "";
  width: 30%;
  left: 10%;
  top: 50%;
}
.Portrait.temp-2 .year::after {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border: 1px solid #fff;
  position: absolute;
  content: "";
  width: 30%;
  right: 10%;
  top: 50%;
}
.Portrait.temp-2 .copyright-text {
  -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
  text-orientation: mixed;
  letter-spacing: 2px;
  position: absolute;
  font-size: 10px;
  z-index: 999;
  left: 1%;
}
.Portrait.temp-2 .company-name {
  position: absolute;
  margin-right: auto;
  text-align: center;
  margin-left: auto;
  z-index: 999999;
  bottom: 34%;
  width: 65%;
  right: 0;
  left: 0;
}
.Portrait.temp-2 .company-name h4 {
  font-size: 1.5rem;
  font-weight: 800;
  margin-bottom: 0;
  color: #fff;
  margin-top: 0;
}
.Portrait.temp-2 .cover-qr-code {
  position: absolute;
  height: auto;
  right: 2%;
  top: 2%;
}
.Portrait.temp-2 .company-logo {
  position: absolute;
  height: auto;
  z-index: 999;
}
.Portrait.temp-2 .title {
  position: absolute;
  text-align: center;
  z-index: 99999;
  color: #fff;
  margin: auto;
  bottom: 10%;
  right: 0;
  left: 0;
}
.Portrait.temp-2 .title h2 {
  font-weight: 800;
  margin: 0;
}
.Portrait.temp-2 .title h4 {
  letter-spacing: 7px;
  margin: 0;
}
.Portrait.temp-2 .cropped-image {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  width: 100%;
}
.Portrait.temp-2 .turn-ar .title h4 {
  letter-spacing: 0;
  font-size: 14px;
}
.Portrait.temp-2 .cover-qr-code {
  width: 20%;
}
.Portrait.temp-2 .company-logo {
  width: 40%;
  left: 4%;
  top: 2%;
}
.Portrait.temp-2 .copyright-text {
  top: 30%;
}
.Portrait.temp-2 .title h2 {
  font-size: 3em;
}
.Portrait.temp-2 .title h4 {
  font-size: 1.1em;
}
.Portrait.temp-2 .curved-corner-topright {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 80%;
  z-index: 99;
  top: 18%;
  right: 0;
  left: 8%;
}
.Portrait.temp-2 .curved-corner-topright::before {
  -webkit-box-shadow: 242px -106px 0 0 #fff;
          box-shadow: 242px -106px 0 0 #fff;
  position: absolute;
  border-radius: 50%;
  display: block;
  height: 200%;
  width: 200%;
  content: "";
  right: 0;
  top: 0;
}
.Portrait.temp-2 .cover-background {
  position: absolute;
  height: 76%;
  width: 100%;
  padding: 0;
  margin: 0;
  top: 24%;
  right: 0;
  left: 0;
}
.Portrait.temp-2 .circle-cover {
  border-radius: 95% 0 0 0;
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: 76%;
  opacity: 1;
  bottom: 0;
  right: 0;
}
.Portrait.temp-2 .circle-cover-2 {
  border-radius: 100% 0 0 0;
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 76%;
  bottom: 5%;
  right: 0;
}
@media only screen and (max-width: 768px) {
  .Portrait.temp-2 .curved-corner-topright {
    left: 2%;
    top: 22%;
  }
  .Portrait.temp-2 .title h2 {
    font-size: 2em;
  }
  .Portrait.temp-2 .title h4 {
    font-size: 0.4em;
  }
  .Portrait.temp-2 .turn-ar .title h4 {
    font-size: 14px;
  }
  .Portrait.temp-2 .company-name h4 {
    font-size: 14px;
  }
  .Portrait.temp-2 .copyright-text {
    top: 25%;
    font-size: 0.3em;
  }
  .Portrait.temp-2 .year p {
    font-size: 10px;
  }
}

.Landscape.temp-2 .year {
  position: absolute;
  margin-right: auto;
  margin-left: auto;
  z-index: 9999;
  width: 100%;
  bottom: 2%;
  right: 0;
  left: 0;
}
.Landscape.temp-2 .year p {
  font-size: 14px;
  color: #fff;
  margin: 0;
}
.Landscape.temp-2 .year::before {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border: 1px solid #fff;
  position: absolute;
  content: "";
  width: 30%;
  left: 10%;
  top: 50%;
}
.Landscape.temp-2 .year::after {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border: 1px solid #fff;
  position: absolute;
  content: "";
  width: 30%;
  right: 10%;
  top: 50%;
}
.Landscape.temp-2 .copyright-text {
  -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
  text-orientation: mixed;
  letter-spacing: 2px;
  position: absolute;
  font-size: 10px;
  z-index: 999;
  left: 1%;
}
.Landscape.temp-2 .company-name {
  position: absolute;
  margin-right: auto;
  text-align: center;
  margin-left: auto;
  z-index: 999999;
  bottom: 34%;
  width: 65%;
  right: 0;
  left: 0;
}
.Landscape.temp-2 .company-name h4 {
  font-size: 1.5rem;
  font-weight: 800;
  margin-bottom: 0;
  color: #fff;
  margin-top: 0;
}
.Landscape.temp-2 .cover-qr-code {
  position: absolute;
  height: auto;
  right: 2%;
  top: 2%;
}
.Landscape.temp-2 .company-logo {
  position: absolute;
  height: auto;
  z-index: 999;
}
.Landscape.temp-2 .title {
  position: absolute;
  text-align: center;
  z-index: 99999;
  color: #fff;
  margin: auto;
  bottom: 10%;
  right: 0;
  left: 0;
}
.Landscape.temp-2 .title h2 {
  font-weight: 800;
  margin: 0;
}
.Landscape.temp-2 .title h4 {
  letter-spacing: 7px;
  margin: 0;
}
.Landscape.temp-2 .cropped-image {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  width: 100%;
}
.Landscape.temp-2 .turn-ar .title h4 {
  letter-spacing: 0;
  font-size: 14px;
}
.Landscape.temp-2 .cover-qr-code {
  width: 10%;
}
.Landscape.temp-2 .company-logo {
  width: 22%;
  left: 8%;
  top: 5%;
}
.Landscape.temp-2 .curved-corner-topright {
  height: 165%;
  left: 30%;
  overflow: hidden;
  position: relative;
  top: -18%;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  width: 50%;
  z-index: 99;
}
.Landscape.temp-2 .curved-corner-topright::before {
  border-radius: 50%;
  -webkit-box-shadow: 190px -100px 0 0 #fff;
          box-shadow: 190px -100px 0 0 #fff;
  content: "";
  display: block;
  height: 200%;
  position: absolute;
  right: 0;
  top: 0;
  width: 200%;
}
.Landscape.temp-2 .cover-background {
  position: absolute;
  height: auto;
  width: 100%;
  padding: 0;
  margin: 0;
  top: 28%;
  left: 0;
}
.Landscape.temp-2 .company-name {
  width: 50%;
}
.Landscape.temp-2 .copyright-text {
  top: 20%;
}
.Landscape.temp-2 .title h2 {
  font-size: 2.5em;
}
.Landscape.temp-2 .title h4 {
  font-size: 0.8em;
}
.Landscape.temp-2 .company-name h4 {
  font-size: 1.2rem;
}
.Landscape.temp-2 .circle-cover {
  border-radius: 95% 0 0 0;
  position: absolute;
  z-index: 9999;
  height: 72%;
  width: 100%;
  opacity: 1;
  bottom: 0;
  right: 0;
}
.Landscape.temp-2 .circle-cover-2 {
  border-radius: 100% 0 0 0;
  position: absolute;
  z-index: 999;
  height: 74%;
  width: 100%;
  bottom: 5%;
  right: 0;
}
@media only screen and (max-width: 768px) {
  .Landscape.temp-2 .curved-corner-topright {
    height: 175%;
    left: 23%;
  }
  .Landscape.temp-2 .curved-corner-topright::before {
    -webkit-box-shadow: 200px -100px 0 0 #fff;
            box-shadow: 200px -100px 0 0 #fff;
  }
}

@media only screen and (max-width: 768px) {
  .homeDiv .Portrait.temp-2 .company-name h4,
  .allCatalogDiv .Portrait.temp-2 .company-name h4 {
    font-size: 25px;
  }
  .homeDiv .Portrait.temp-2 .curved-corner-topright,
  .allCatalogDiv .Portrait.temp-2 .curved-corner-topright {
    left: 8%;
    top: 18%;
  }
}

@media only screen and (max-width: 768px) {
  .homeDiv .Landscape.temp-2 .company-name h4,
  .allCatalogDiv .Landscape.temp-2 .company-name h4 {
    font-size: 25px;
  }
}

/* 
Perview Style (when create catalog)
*/
.Portrait.temp-1.perview .rectangle-center {
  border-radius: 8px;
}
.Portrait.temp-1.perview .rectangle-center-inside {
  border-radius: 4px;
}
.Portrait.temp-1.perview .rectangle-center-company-logo {
  border-radius: 4px;
}
.Portrait.temp-1.perview .company-name h4 {
  font-size: 8px;
}
.Portrait.temp-1.perview .title h2 {
  font-size: 1em;
}
.Portrait.temp-1.perview .title h4 {
  font-size: 0.1rem;
  letter-spacing: 4px;
}
.Portrait.temp-1.perview .year p {
  font-size: 7px;
}
.Portrait.temp-1.perview .copyright-text {
  letter-spacing: 1px;
  font-size: 0.1em;
}

.Landscape.temp-1.perview .rectangle-center {
  border-radius: 8px;
}
.Landscape.temp-1.perview .rectangle-center-inside {
  border-radius: 4px;
}
.Landscape.temp-1.perview .rectangle-right {
  right: -19%;
  --r: 10px;
}
.Landscape.temp-1.perview .rectangle-center-company-logo {
  border-radius: 4px;
}
.Landscape.temp-1.perview .company-name h4 {
  font-size: 8px;
}
.Landscape.temp-1.perview .title h2 {
  font-size: 0.5em;
}
.Landscape.temp-1.perview .title h4 {
  font-size: 0.1rem;
  letter-spacing: 1px;
}
.Landscape.temp-1.perview .year p {
  font-size: 3px;
}
.Landscape.temp-1.perview .copyright-text {
  letter-spacing: 0.5px;
  font-size: 0.1em;
}

.Portrait.temp-2.perview .curved-corner-topright {
  background-color: #fff;
  left: 5%;
}
.Portrait.temp-2.perview .rectangle-center {
  border-radius: 8px;
}
.Portrait.temp-2.perview .rectangle-center-inside {
  border-radius: 4px;
}
.Portrait.temp-2.perview .rectangle-center-company-logo {
  border-radius: 4px;
}
.Portrait.temp-2.perview .company-name h4 {
  font-size: 6px;
}
.Portrait.temp-2.perview .title h2 {
  font-size: 0.7em;
}
.Portrait.temp-2.perview .title h4 {
  font-size: 0.1rem;
  letter-spacing: 2.5px;
}
.Portrait.temp-2.perview .year p {
  font-size: 7px;
}
.Portrait.temp-2.perview .copyright-text {
  letter-spacing: 1px;
  font-size: 0.1em;
}

.Landscape.temp-2.perview .curved-corner-topright {
  background-color: #fff;
  left: 5%;
}
.Landscape.temp-2.perview .rectangle-center {
  border-radius: 8px;
}
.Landscape.temp-2.perview .rectangle-center-inside {
  border-radius: 4px;
}
.Landscape.temp-2.perview .rectangle-center-company-logo {
  border-radius: 4px;
}
.Landscape.temp-2.perview .company-name h4 {
  font-size: 4px;
}
.Landscape.temp-2.perview .title h2 {
  font-size: 0.5em;
}
.Landscape.temp-2.perview .title h4 {
  font-size: 0.1rem;
  letter-spacing: 1.5px;
}
.Landscape.temp-2.perview .year p {
  font-size: 4px;
}
.Landscape.temp-2.perview .copyright-text {
  letter-spacing: 1px;
  font-size: 0.1em;
}

.customized-catalog {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.customized-catalog .year p {
  font-size: 14px;
  margin: 0;
}
.customized-catalog .year::before {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border: 1px solid #000;
  position: absolute;
  content: "";
  width: 30%;
  left: 10%;
  top: 50%;
}
.customized-catalog .year::after {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border: 1px solid #000;
  position: absolute;
  content: "";
  width: 30%;
  right: 10%;
  top: 50%;
}/*# sourceMappingURL=templates.css.map */