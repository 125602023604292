body,
html {
  font-variation-settings: "slnt" 0;
  font-family: "Cairo", sans-serif;
  font-optical-sizing: auto;
}

.page-style-cover {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.page-style-cover.customize {
  position: unset;
}

.customize .page-style-cover{
  overflow: auto;
}

.catelog-cover {
  background-color: #fff;
  text-align: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.catalog-cover-border {
  background-color: transparent;
  position: absolute;
  z-index: 999999;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}

.customerLogo {
  width: 100%;
}
