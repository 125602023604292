@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap");

body.direction-rtl {
  direction: rtl;
  text-align: right;
}

body.create-catalog {
  overflow: hidden;
}

a {
  text-decoration: none !important;
}

/* em {
  font-style: italic !important;
} */

section {
  padding-top: 6rem !important;
  padding-bottom: 3rem !important;
}

.form-label {
  font-size: 12px;
}

.mt-7 {
  margin-top: 7rem !important;
}

.btn-primary {
  background-color: #048ed6 !important;
  border-color: #048ed6 !important;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:first-child:active,
.btn-primary.active {
  background-color: #003159 !important;
  border-color: #003159 !important;
}

.form-control {
  margin: 0 auto;
}

.create-catalog {
  overflow: hidden;
}

.left-fixed-navbar {
  height: 100vh;
  position: fixed;
  background-color: #048ed6;
}

.selected-left-item {
  padding: 10px 0;
  background-color: #ffffff;
}

.top-fixed-navbar {
  background-color: rgb(4, 142, 214);
  text-align: center;
  position: fixed;
  color: #ffffff;
  height: 120px;
  width: 82%;
  z-index: 9;
}

.top-fixed-navbar .customerLogo {
  width: 60px;
}

.center-content {
  margin-top: 9rem;
  position: relative;
}

.right-fixed-navbar {
  width: 134px;
  position: fixed;
  padding: 10px 10px 10px 0px;
  background-color: rgb(4, 142, 214);
}

.view {
  color: #ffffff;
}

.view:hover {
  cursor: pointer;
}

.top-header {
  background-color: #186da4;
  width: 100%;
  height: 15px;
}

.selected div.item {
  margin: 0 1rem;
}

.selected span {
  margin: 0 auto;
  /* width: 20px; */
  height: 20px;
  display: block;
}

.main {
  position: relative;
}

.modal.fade .modal-dialog.modal-dialog-zoom {
  -webkit-transform: translate(0, 0) scale(0.5);
  transform: translate(0, 0) scale(0.5);
}

.modal.show .modal-dialog.modal-dialog-zoom {
  -webkit-transform: translate(0, 0) scale(1);
  transform: translate(0, 0) scale(1);
}

.bg-header {
  background-color: #f1f1f1;
  margin-top: 4.6rem;
  padding-top: 3rem !important;
}

.bg-header-dark {
  background-color: #186da4;
}

.bg-header-dark a {
  color: #ffffff;
  text-decoration: none;
}

.position-fixed {
  left: 0;
  right: 0;
  top: 0;
  z-index: 99;
}

.header-image {
  -webkit-filter: drop-shadow(0px 15px 10px #00000054);
  filter: drop-shadow(0px 15px 10px #00000054);
  height: 330px;
  width: 100%;
  object-fit: contain;
}

.loading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #186da473;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-search {
  background-color: #186da4 !important;
  color: #ffffff !important;
  border: none !important;
}

.logo-image {
  width: 95px;
  height: auto;
  margin: 0 auto;
}

.carousel-control-next,
.carousel-control-prev,
.carousel-indicators {
  display: none !important;
}

.carousel-indicators [data-bs-target] {
  background-color: #048ed6;
}

.carousel-item {
  padding: 3rem;
}

.features .icon {
  color: #ffffff;
  width: 75px;
  height: 75px;
  display: flex;
  font-size: 1.5rem;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem auto;
  background-color: #048ed6;
}

.about-icon {
  width: 85% !important;
  margin-left: auto;
  display: block;
}

.section-title {
  position: relative;
  margin-bottom: 1rem;
  font-weight: bold;
}

.section-title:after {
  content: "";
  display: block;
  width: 65px;
  height: 5px;
  background-color: #048ed6;
  position: absolute;
  bottom: -10px;
}

.aggregator .all-catalogs {
  position: absolute;
  left: 50%;
  bottom: 0;
}

.page-link:hover {
  cursor: pointer;
}

.turn-page-wrapper {
  perspective: 2000px;
}

.shelf-books {
  margin-top: 45px;
  direction: ltr !important;
  position: relative;
}

.shelf-books .catalgos {
  background-image: url("https://smartcatalog.sinaha.ae/assets/images/shelf-wood-brown.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
  transition: all 1s;
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 400px;
  top: 2rem;
  right: 0;
  left: 0;
}

.shelf-books .catalgos .overlay-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: -150px;
  bottom: 0;
  background-color: #ffffffff;
  display: none;
  z-index: 9;
  width: 1550px;
}

.catalog-control {
  background-color: #048ed6 !important;
  border-radius: 5px;
  height: 57px;
  display: none;
  position: absolute;
  top: -100px;
  transition: opacity 0.1s;
  z-index: 999;
  left: 0;
  right: 0;
  padding: 10px;
  margin: 1.5rem 0;
}

.catalog-control.perviewCatalogFromCreate {
  height: 62px;
  top: -140px;
  right: 2px;
}

.catalog-control.active {
  display: block;
}

.catalog-control #close {
  background-color: #ff0000;
  border-radius: 5px;
  padding: 0 9px;
}

.catalog-control .dropdown-item {
  color: #ffffff;
}

.catalog-control .dropdown-item:focus,
.catalog-control .dropdown-item:hover {
  color: #000000;
}

.catalog-control .dropdown-menu {
  background-color: #048ed6 !important;
}

.catalog-control .search-input {
  background-color: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
}

.catalog-control .search-input::placeholder {
  color: #ffffff;
}

.shelf-books .catalgos .catalog-cover-old {
  transition: transform 1s;
  transform: translate(0, 0);
  background: transparent;
  perspective: 1000px;
  border: unset;
}

.shelf-books .catalgos .catalog-cover-old:hover {
  z-index: 1;
  position: relative;
  cursor: pointer;
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
}

.shelf-books .catalgos .catalog-cover-old:hover img {
  transform: rotateY(0);
}

.shelf-books .catalgos .catalog-cover {
  position: relative;
  transition: transform 1s;
  -webkit-transition: -webkit-transform 1s;
  -moz-transition: -moz-transform 1s;
  -ms-transition: -ms-transform 1s;
  -o-transition: -o-transform 1s;
  background: transparent;
  perspective: 1000px;
  border: unset;
  width: 750px;
  height: 500px;
  transform: translate(-18%, -3%) scale(0.3);
  -o-transform: translate(-18%, -3%) scale(0.3);
  -ms-transform: translate(-18%, -3%) scale(0.3);
  -moz-transform: translate(-18%, -3%) scale(0.3);
  -webkit-transform: translate(-18%, -3%) scale(0.3);
}

.shelf-books .catalgos .catalog-cover:hover {
  cursor: pointer;
  transform: translate(-18%, -3%) scale(0.35);
  -o-transform: translate(-18%, -3%) scale(0.35);
  -ms-transform: translate(-18%, -3%) scale(0.35);
  -moz-transform: translate(-18%, -3%) scale(0.35);
  -webkit-transform: translate(-18%, -3%) scale(0.35);
  z-index: 999;
}

.shelf-books .catalgos .catalog-cover.active {
  z-index: 99;
}

.catalog-cover .page {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.shelf-books .catalgos .catalog-cover:after {
  left: 8px;
  width: 80%;
  height: 0px;
  bottom: -13px;
  content: "";
  z-index: -1;
  display: block;
  position: absolute;
  transform: rotate(-10deg);
  box-shadow: -8px -5px 11px 14px #00000087;
  transition: transform 1s, width 1s, bottom 1s;
}

.shelf-books .catalgos .catalog-cover-old:after {
  left: 22px;
  width: 76%;
  height: 0px;
  bottom: 0px;
  content: "";
  z-index: -1;
  display: block;
  position: absolute;
  transform: rotate(-4deg);
  transition: transform 1s;
  box-shadow: 0px 0px 11px 5px #000000;
}

.shelf-books .catalgos .catalog-cover.active:after {
  box-shadow: unset;
  transform: translate(115%, 0) rotate(0);
  bottom: 5%;
  width: 0%;
  right: -50%;
  left: unset;
}

.shelf-books .catalgos .catalog-cover-old:hover:after {
  transform: rotate(0);
}

.shelf-books .catalgos .catalog-cover-old img {
  transition: transform 1s;
  transform: rotateY(40deg);
}

.shelf-books .catalgos .catalog-cover .book-container {
  transition: transform 1s;
  transform: rotateY(33deg);
  position: relative;
  perspective: 995px;
}

.shelf-books .catalgos .catalog-cover .book-container:after {
  content: "";
  position: absolute;
  width: 7%;
  background-color: #cccccc;
  height: 98%;
  top: 0.5%;
  z-index: -1;
  opacity: 1;
  transform: translate(-40px, 4px) rotateY(-40deg);
  box-shadow: inset 1px 1px 9px 9px #a7a7a7;
  transition: all 1s;
}

.shelf-books .catalgos .catalog-cover.active .book-container:after {
  transform: translate(780px, 4px) rotateY(-40deg);
  opacity: 0;
}

.shelf-books .catalgos .catalog-cover.active:hover .book-container:after {
  content: unset;
}

.shelf-books .catalgos .catalog-cover.active .book-container {
  transform: rotateY(0);
}

.catalog-cover .page-style {
  display: none;
}

.back-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.shelf-books
  .catalgos
  .catalog-cover
  .book-container
  .turn-page-wrapper.first-page {
  transition: transform 1s;
  -webkit-transition: -webkit-transform 1s;
  -moz-transition: -moz-transform 1s;
  -ms-transition: -ms-transform 1s;
  -o-transition: -o-transform 1s;
}

.shelf-books
  .catalgos
  .catalog-cover
  .book-container
  .turn-page-wrapper.first-page {
  transform: translate(-100%, 0%);
}

.catalog-cover .turn-page-wrapper.first-page .page-style {
  display: block !important;
}

.shelf-books
  .catalgos
  .catalog-cover.active
  .book-container
  .turn-page-wrapper.first-page {
  transform: translate(0%, 0%);
}

.shelf {
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  filter: drop-shadow(00px 10px 10px #00000050);
}

.shelf img {
  width: 100%;
}

.marketing {
  -webkit-transition: -webkit-opacity 1s;
  transition: opacity 1s ease-in-out;
  -moz-transition: -moz-opacity 1s;
  -ms-transition: -ms-opacity 1s;
  -o-transition: -o-opacity 1s;
  position: relative;
  overflow: hidden;
  height: 1300px;
}

.animated-div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transform: scale3d(0, 0, 1);
  transition: opacity 1s ease-out, transform 1s;
  width: 100%;
  height: 100vh;
  background-color: transparent;
}

.close {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 9999;
}

.contact .card .card-body,
.contact .card {
  transition: all 0.3s;
}

.contact .card:hover {
  box-shadow: inset 0px 0px 20px 0px #186da44d;
  color: #186da4;
}

.contact .card:hover .card-body {
  transform: scale(0.9);
}

.go-up {
  position: absolute;
  font-size: 2rem;
  height: 60px;
  right: 5rem;
  width: 60px;
  color: #ffffff;
}

/* Create catalog */

.quill {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  height: 100% !important;
}

/* Fonts */
.ql-font-cairo {
  font-family: "Cairo", sans-serif;
}
.ql-font-georgia {
  font-family: "Georgia", serif;
}
.ql-font-papyrus {
  font-family: Papyrus, fantasy;
}
.ql-font-tahoma {
  font-family: Tahoma, Geneva, sans-serif;
}
.ql-font-arial {
  font-family: Arial, Helvetica, sans-serif;
}
.ql-font-verdana {
  font-family: Verdana, Geneva, sans-serif;
}
.ql-font-impact {
  font-family: Impact, Charcoal, sans-serif;
}
.ql-font-helvetica {
  font-family: Helvetica, Arial, sans-serif;
}
.ql-font-courier {
  font-family: "Courier New", Courier, monospace;
}
.ql-font-lucida {
  font-family: "Lucida Console", Monaco, monospace;
}
.ql-font-consolas {
  font-family: Consolas, "Courier New", monospace;
}
.ql-font-brush-script {
  font-family: "Brush Script MT", cursive;
}
.ql-font-trebuchet {
  font-family: "Trebuchet MS", Helvetica, sans-serif;
}
.ql-font-times-new-roman {
  font-family: "Times New Roman", Times, serif;
}
.ql-font-comic-sans {
  font-family: "Comic Sans MS", cursive, sans-serif;
}
.ql-font-palatino {
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="cairo"]::before {
  content: "Cairo";
  font-family: "Cairo", sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="georgia"]::before {
  content: "Georgia";
  font-family: "Georgia", serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="papyrus"]::before {
  content: "Papyrus";
  font-family: Papyrus, fantasy;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="tahoma"]::before {
  content: "Tahoma";
  font-family: Tahoma, Geneva, sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="arial"]::before {
  content: "Arial";
  font-family: Arial, Helvetica, sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="verdana"]::before {
  content: "Verdana";
  font-family: Verdana, Geneva, sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="impact"]::before {
  content: "Impact";
  font-family: Impact, Charcoal, sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="helvetica"]::before {
  content: "Helvetica";
  font-family: Helvetica, Arial, sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="courier"]::before {
  content: "Courier New";
  font-family: "Courier New", Courier, monospace;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="lucida"]::before {
  content: "Lucida Console";
  font-family: "Lucida Console", Monaco, monospace;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="consolas"]::before {
  content: "Consolas";
  font-family: Consolas, "Courier New", monospace;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="brush-script"]::before {
  content: "Brush Script MT";
  font-family: "Brush Script MT", cursive;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="trebuchet"]::before {
  content: "Trebuchet MS";
  font-family: "Trebuchet MS", Helvetica, sans-serif;
}

.ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="times-new-roman"]::before {
  content: "Times New Roman";
  font-family: "Times New Roman", Times, serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="comic-sans"]::before {
  content: "Comic Sans MS";
  font-family: "Comic Sans MS", cursive, sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="palatino"]::before {
  content: "Palatino";
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
}

/**/

.react-resizable {
  border: 1px solid #777;
}

.react-resizable > div:first-child > div:first-child {
  height: 100%;
}

.delete-cover {
  background-color: #f24537;
  justify-content: center;
  border-radius: 10px;
  align-items: center;
  position: absolute;
  font-size: 8px;
  display: flex;
  outline: none;
  border: none;
  height: 16px;
  width: 16px;
  color: #ffffff;
  right: 4px;
  top: 4px;
}

.pages-content {
  flex: 1;
  overflow: hidden;
  width: 100%;
}

.pages-scrollable-content {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}

.pages-scrollable-content::-webkit-scrollbar {
  width: 3px; /* Width of the scrollbar */
}

.pages-scrollable-content::-webkit-scrollbar-thumb {
  background-color: #fffffffff; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded corners */
}

.pages-scrollable-content::-webkit-scrollbar-thumb:hover {
  background-color: #fffffffff; /* Color of the scrollbar thumb on hover */
}

.pages-footer {
  text-align: center;
}

.page-button {
  width: 100%;
  padding: 5px;
  overflow: auto;
  margin: 1rem 0px;
  position: relative;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
}

.page-button::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.page-button::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 4px;
}

.page-button::-webkit-scrollbar-thumb:hover {
  background-color: #000000;
}

.page-button:hover,
.page-button.active {
  cursor: pointer;
  background-color: #ffffff;
}

.page-button.active::before {
  content: "";
}

.pages-new {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #048ed6;
  border: none;
}

.remove-page {
  top: 5px;
  right: 5px;
  z-index: 99;
  color: #fff;
  font-size: 10px;
  padding: 0px 3px;
  position: absolute;
  background-color: #f00;
}

.remove-page:hover {
  cursor: pointer;
}

.css-5736gi-menu {
  z-index: 9999;
}

.editing-line-horizontal,
.editing-line-vertical {
  z-index: 99;
  position: absolute;
  background-color: #ff00005c;
}

.editing-line-horizontal {
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  transform: translateY(-50%);
}

.editing-line-vertical {
  left: 50%;
  top: 0;
  height: 100%;
  width: 2px;
  transform: translateX(-50%);
}

/* Page */
.page-style {
  height: 100%;
  overflow: auto;
  position: relative;
  background-color: #eee;
}

/* .create-catalog .page-style{
  overflow: auto;
} */
.multiple-selection {
  width: 370px;
  margin: 0 auto;
}

.react-resizable-handle-e {
  right: 4px;
}

.css-n1eb7n {
  max-height: 66px;
}

.products-list {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  height: 100%;
  overflow: hidden;
  padding: 0px 0 0 0;
  position: absolute;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.products-row {
  align-content: flex-start;
  padding: 15px;
  align-items: center;
  height: 100%;
}

.imagesListRowsColumns {
  width: 100%;
  height: 100%;
  display: grid;
  position: absolute;
}

.imagesListRowsColumns img:hover {
  cursor: pointer;
}

.pages-content .products-row {
  padding: 0px 15px 6px 15px;
}

.products-row .col-md-4 {
  height: 33%;
  margin: 4px 0;
  padding-right: 8px;
  padding-left: 8px;
}

.products-row .col-4 {
  height: calc((100% / 3) - 8px);
}

.products-row .smallView.col-4 {
  height: calc((100% / 3) - 3px);
}

.pages-contetn .products-row .col-4 {
  height: calc((100% / 3) - 0px);
}

.item-card {
  padding: 3px 3px 0 3px;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #eee;
  height: 100%;
}

.smallView .item-card {
  padding: 0px;
}

.pages-content .item-card {
  border-radius: 3px;
}

.cursor-pointer {
  cursor: pointer;
}

.item-card .item-image {
  border-radius: 10px 10px 0 0;
  /* background-repeat: no-repeat; */
  /* border: 1px solid #eee; */
  /* background-size: contain; */
  /* background-position: center; */
  height: 40%;
}

.item-card .item-image img {
  background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 100%;
    height: 100%;
}

.smallView .item-card .item-image {
  position: relative;
  border-radius: 0;
}

.smallView .item-card .item-image img {
  position: absolute;
}

.item-description {
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 23%;
  font-size: 10px;
}

.smallView .item-description {
  font-size: 3px;
}

.Landscape .item-description {
  height: 20%;
  width: 100%;
}

.item-details {
  position: relative;
  padding: 0 3px;
  height: 60%;
}

.item-details p {
  height: 20%;
  font-size: 14px;
  font-weight: 800;
  line-height: 13px;
  margin: 4px 0;
  overflow: auto;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.smallView .item-details p {
  font-size: 3px;
  line-height: 5px;
}

.pages-content .item-details p {
  margin: 0;
}

.item-details h5 {
  font-size: 9px;
  font-weight: 600;
  margin-bottom: 0;
}

.item-details .qr-code {
  position: absolute;
  height: 35px;
  width: 35px;
  bottom: 9%;
  right: 2%;
}

.smallView .item-details .qr-code {
  height: 6px;
  width: 6px;
}

/* Modal */
.product-modal .product-image {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
}

.product-modal .product-details {
  margin-top: 1rem;
}

.product-modal .product-details h5 {
  font-size: 11px;
}

.product-modal .qr-code-image {
  height: auto;
  width: 15%;
}

.product-modal .icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-modal .icons img.icon {
  width: 23%;
}
/**/

.sin-product-verfiy-logos {
  display: flex;
  justify-content: center;
  margin: 0.8rem 0 0;
  position: absolute;
  right: 50%;
  left: 2%;
  bottom: 8%;
}

.sin-product-verfiy-logos .sin-product-verfiy-logo {
  margin-right: 0.4rem;
}

.sin-product-verfiy-logos .sin-product-verfiy-logo img {
  /* width: 70%;
  height: auto; */
  width: 25px;
  height: 25px;
}

.smallView .sin-product-verfiy-logos .sin-product-verfiy-logo img {
  width: 6px;
  height: 6px;
}

.smallView .sin-product-verfiy-logos .sin-product-verfiy-logo {
  margin-right: 0;
}

.page-style.even {
  background-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0) 98%,
    rgba(0, 0, 0, 0.1) 100%
  );
}

.page-style.odd {
  background-image: -webkit-linear-gradient(
    right,
    rgba(0, 0, 0, 0) 98%,
    rgba(0, 0, 0, 0.1) 100%
  );
}

.even-shadow {
  background-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0) 98%,
    rgba(0, 0, 0, 0.1) 100%
  );
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 3%;
  height: 100%;
  /* z-index: 9; */
  position: absolute;
}

.odd-shadow {
  background-image: -webkit-linear-gradient(
    right,
    rgba(0, 0, 0, 0) 98%,
    rgba(0, 0, 0, 0.1) 100%
  );
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 3%;
  height: 100%;
  /* z-index: 9; */
  position: absolute;
}

.overflow-image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.qr-code-content {
  background-color: #fff;
  position: absolute;
  padding: 10px;
  right: 10%;
  z-index: 9;
  top: 0;
}

.qr-code-content .qr-code {
  width: 100%;
}

.glass-effect {
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 0 10px 10px 0;
  backdrop-filter: blur(5px);
  position: absolute;
  padding: 10px;
  color: #fff;
  left: 0;
  top: 4%;
  width: 60%;
  font-weight: bold;
}

.glass-effect.description {
  bottom: 15%;
  width: 100%;
  font-size: 14px;
  font-weight: 100;
  border-radius: unset;
  top: unset;
}

.footer {
  padding: 1px 0;
  background-color: #000000;
  text-align: center;
  color: #fff;
  border-radius: 10px 10px 0 0;
  font-size: 9px;
  position: absolute;
  bottom: 1px;
  left: 0;
  width: 100%;
  background-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0) 95%,
    rgba(0, 0, 0, 0.1) 100%
  );
}

.pages-content .footer {
  font-size: 2px;
}

.company-profile {
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
  font-size: 0.7rem;
  font-size: 0.7rem;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  border-top: 1px solid #9d9d9d;
  border-left: 1px solid #9d9d9d;
  border-bottom: 1px solid #9d9d9d;
  background-position: center center;
}

.company-profile::-webkit-scrollbar {
  width: 0.5rem;
}

.company-profile::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.company-profile::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

.catelog-back-cover {
  background-position: center center;
  background-repeat: no-repeat;
  /* background-color: #000000; */
  justify-content: center;
  background-size: cover;
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
}

.catelog-back-cover .back-cover-logo {
  justify-content: center;
  background-color: #fff;
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 50%;
  width: 35%;
}

.catelog-back-cover .back-cover-logo img {
  object-fit: contain;
  width: 50%;
}

/* Portrait */
.shelf-books .catalgos .catalog-cover.Portrait {
  transform: translate(-15%, -27%) scale(0.35);
}

.shelf-books .catalgos .catalog-cover.Portrait .book-container {
  transform: rotateY(20deg);
}

.shelf-books .catalgos .catalog-cover.active.Portrait .book-container {
  transform: rotateY(0deg);
}

.shelf-books .catalgos .catalog-cover.Portrait:hover {
  transform: translate(-15%, -27%) scale(0.4);
  -o-transform: translate(-15%, -27%) scale(0.4);
  -ms-transform: translate(-15%, -27%) scale(0.4);
  -moz-transform: translate(-15%, -27%) scale(0.4);
  -webkit-transform: translate(-15%, -27%) scale(0.4);
}

.shelf-books .catalgos .catalog-cover.Portrait:hover:after {
  bottom: -66%;
  left: -30px;
  width: 75%;
}

.shelf-books .catalgos .catalog-cover.Portrait:after {
  bottom: -65%;
  box-shadow: -8px -5px 11px 14px #00000087;
  content: "";
  display: block;
  height: 0;
  left: -11px;
  position: absolute;
  transform: rotate(-12deg);
  transition: transform 1s, width 1s, bottom 1s;
  width: 70%;
  z-index: -1;
}

.shelf-books .catalgos .catalog-cover.Portrait.active {
  transform: translate(-15%, 0);
}

.shelf-books .catalgos .catalog-cover.Portrait.active:after {
  box-shadow: unset;
  transform: translate(115%, 0) rotate(0);
  bottom: 5%;
  width: 0%;
  right: -50%;
  left: unset;
}

.navbar-nav-customize {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  justify-content: space-evenly;
  align-items: center;
  font-size: 16px;
}

.navbar-nav-customize .nav-item {
  margin-right: 1.3rem !important;
  margin-left: 1.3rem !important;
}

.navbar-nav-customize .nav-link {
  color: #fff !important;
}

/* catalogPage */
.catalogPage {
  height: 100vh;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  justify-content: center;
  direction: ltr !important;
  background-image: url("../../public/assets/images/bg.png");
}

.catalogPage .catalogPageOverlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #fffffff7;
}

.catalogPage .catalog-cover .page-style,
.catalogPageDiv .catalog-cover .page-style {
  display: block;
}

.catalogPage .catalog-control {
  top: 0;
  margin: 0px;
  position: fixed;
  border-radius: 0px;
}

/* Portrait */
.catalogPage .catalog-cover.Portrait {
  width: 60%;
}

.catalogPage .moved-object {
  position: absolute;
  left: 13%;
  top: 30%;
}

.direction-rtl .shelf-books .catalgos .catalog-cover .book-container:after {
  transform: translate(-790px, 4px) rotateY(-40deg);
}

.direction-rtl .navbar-brand {
  margin-right: 0 !important;
  margin-left: 1rem !important;
}

.direction-rtl .navbar-nav.me-auto {
  margin-right: unset !important;
  margin-left: auto !important;
}

.catalogPage .search-input {
  margin-left: 0rem !important;
  margin-right: 0.5rem !important;
}

.page-item {
  cursor: pointer;
}

.tabs-items .tab-item a {
  color: #048ed6;
  font-size: 16px;
  font-weight: 800;
}

.tabs-items .tab-item.active a {
  color: #003159;
}

.tabs-items .tab-item.active {
  border-bottom: 4px solid #003159;
}

.tabs-items .category-item {
  margin: 0.2rem 0;
  position: relative;
}

.tabs-items .category-item.active-category::after {
  left: 0;
  right: 0;
  width: 15%;
  height: 4px;
  content: "";
  bottom: -5px;
  margin: 0 auto;
  position: absolute;
  background-color: #003159;
}

.tabs-items .category-item a {
  font-size: 14px;
  color: #777;
}

.tabs-items .category-item.active-category a {
  color: #003159;
}

.skeleton {
  width: 100%;
  height: 320px;
  background-color: #eee;
}

.select2-container {
  z-index: 9999;
}

.bordered-box {
  width: 100%;
  padding: 20px;
  margin-top: 15px;
  position: relative;
  border-radius: 10px;
  border: 1px solid #dee2e6;
}

.bordered-box .label {
  top: -13px;
  left: 10px;
  padding: 0 10px;
  font-weight: bold;
  position: absolute;
  background-color: transparent;
}

.hide-nav {
  display: none !important;
}

.body-class.homeDiv .open_catalog_link {
  display: none;
}

.shelf-single {
  display: none;
}

.products-list-create-page {
  height: 64rem;
  overflow: auto;
}

.customerLogo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.circle,
.square,
.triangle,
.line {
  margin: 0 0.5rem;
  /*display: flex;
  justify-content: center;
  align-items: center;*/
}

.text {
  height: 30px;
  display: flex;
  font-size: 2rem;
  font-family: auto;
  align-items: center;
  justify-content: center;
}

.text:hover {
  cursor: pointer;
}

.ql-size-small {
  font-size: 0.75em;
}

.ql-size-large {
  font-size: 1.5em;
}

.ql-size-huge {
  font-size: 2.5em;
}

.smallView {
  font-size: 0.3rem;
}

.smallView font,
.smallView font span,
.smallView font b,
.smallView font p {
  font-size: 0.3rem !important;
}

.smallView .ql-size-small {
  font-size: 0.1rem;
}

.smallView .ql-size-large {
  font-size: 0.5rem;
}

.smallView .ql-size-huge {
  font-size: 0.7rem;
}

.circle:hover,
.square:hover,
.triangle:hover,
.line:hover {
  cursor: pointer;
}

.square {
  width: 30px;
  height: 30px;
  background-color: #eee;
}

.circle {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: #eee;
}

.triangle {
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  background-color: #eee;
  width: 30px;
  height: 30px;
  /* width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 30px solid #eee; */
}

.line {
  width: 3px;
  height: 30px;
  background-color: #eee;
}

/* .handle,
.color input,
.bold button,
.zIndex input,
.italic button,
.fontSize input,
.fontName select,
.underline button,
.borderColor input,
.borderWidth input,
.borderRadius input,
.borderStyle select,
.show button.show_button,
.rotatable {
  position: absolute;
  height: 20px;
  width: 20px;
  top: -25px;
} */

.resizableAndDraggableBoxNavBar {
  top: 0;
  bottom: 0;
  left: 10%;
  z-index: 9;
  width: 150px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  position: absolute;
  border: 1px solid #777;
  flex-direction: column;
}

.resizableAndDraggableBoxNavBar .tools-item {
  width: 100%;
  margin: 0.1rem 0;
}

.resizableAndDraggableBoxNavBar .tools-item span {
  display: block;
  font-size: 0.6rem;
  /* margin-bottom: 0.1rem; */
}

.resizableAndDraggableBoxNavBar .tools-item .text-align:hover {
  cursor: pointer;
}

.resizableAndDraggableBoxNavBar .tools-item .text-align.active {
  color: #f00;
}

/* .resizableAndDraggableBoxNavBar > * {
  margin: 5px;
} */

.resizableAndDraggableBoxNavBar .show-button,
.resizableAndDraggableBoxNavBar .bold-button,
.resizableAndDraggableBoxNavBar .delete-button,
.resizableAndDraggableBoxNavBar .handle-button,
.resizableAndDraggableBoxNavBar .italic-button,
.resizableAndDraggableBoxNavBar .bg-color-input,
.resizableAndDraggableBoxNavBar .font-color-input,
.resizableAndDraggableBoxNavBar .border-color-input,
.resizableAndDraggableBoxNavBar .underline-button {
  font-size: 11px;
  border: none;
  padding: 0px;
  height: 20px;
  width: 100%;
  /* width: 20px; */
}

.resizableAndDraggableBoxNavBar .bg-color-input,
.resizableAndDraggableBoxNavBar .font-color-input,
.resizableAndDraggableBoxNavBar .border-color-input {
  margin-top: 0.25rem;
  width: 100%;
}

.resizableAndDraggableBoxNavBar .show-button,
.resizableAndDraggableBoxNavBar .bold-button,
.resizableAndDraggableBoxNavBar .delete-button,
.resizableAndDraggableBoxNavBar .handle-button,
.resizableAndDraggableBoxNavBar .italic-button,
.resizableAndDraggableBoxNavBar .bg-color-input,
.resizableAndDraggableBoxNavBar .border-color-input,
.resizableAndDraggableBoxNavBar .underline-button {
  /* justify-content: center; */
  /* align-items: center; */
  background: #ccc;
  cursor: pointer;
  width: 100%;
  /* display: flex; */
}

.resizableAndDraggableBoxNavBar .delete-button {
  color: #f00;
}

.resizableAndDraggableBoxNavBar .handle-button {
  cursor: move;
}

.handle-button {
  left: 0;
  width: 30px;
  z-index: 99;
  height: 30px;
  cursor: move;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: #eeeeee7d;
}

.resizableAndDraggableBoxNavBar .bold-button.active,
.resizableAndDraggableBoxNavBar .italic-button.active,
.resizableAndDraggableBoxNavBar .underline-button.active {
  width: 100%;
  font-weight: 900;
  border: 2px solid #000;
}

.resizableAndDraggableBoxNavBar .font-name-select,
.resizableAndDraggableBoxNavBar .font-size-input,
.resizableAndDraggableBoxNavBar .border-width-input,
.resizableAndDraggableBoxNavBar .opacity-input,
.resizableAndDraggableBoxNavBar .border-style-select,
.resizableAndDraggableBoxNavBar .border-radius-input,
.resizableAndDraggableBoxNavBar .object-fit-select,
.resizableAndDraggableBoxNavBar .object-position-select,
.resizableAndDraggableBoxNavBar .browse-input,
.resizableAndDraggableBoxNavBar .z-index-input {
  font-size: 10px;
  height: 20px;
  width: 100%;
}

.resizableAndDraggableBoxNavBar .browse-input {
  height: auto;
}

.borderLabel {
  top: -60px;
  left: 75px;
  width: 213px;
  font-size: 11px;
  position: absolute;
}

.color-picker {
  display: flex;
  align-items: center;
}

.color-preview {
  width: 50px;
  height: 50px;
  margin-left: 10px;
  border: 1px solid #ccc;
}

.borderWidth input {
  left: 75px;
  width: 40px;
  font-size: 10px;
}

.borderRadius input {
  left: 245px;
  width: 40px;
  font-size: 10px;
}

/* .zIndex input,
input {
  left: 200px;
  width: 40px;
  font-size: 10px;
} */

.borderStyle select {
  left: 120px;
  width: 50px;
  font-size: 10px;
}

.bold button {
  left: 213px;
}

.italic button {
  left: 237px;
}

.underline button {
  left: 261px;
}

.bold.profile button {
  left: 285px;
}

.bold button,
.italic button,
.underline button {
  display: flex;
  font-size: 11px;
  align-items: center;
  border: 1px solid #777;
  justify-content: center;
}

.bold button.active,
.italic button.active,
.underline button.active {
  font-weight: bold;
  border: none;
  background-color: #000;
  color: #fff;
}

.show button.show_button {
  padding: 0;
  left: 215px;
  border: none;
  text-align: center;
  background-color: transparent;
}

.show.logo button.show_button {
  left: 25px;
}

.rotatable {
  top: 0px;
  left: 0px;
  z-index: 9;
  width: auto;
  right: 10px;
  bottom: 10px;
  height: auto;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-rotate-cw"><polyline points="23 4 23 10 17 10"></polyline><path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"></path></svg>'),
    auto;
}

.fontSize input {
  font-size: 10px;
  left: 50px;
  width: 50px;
}

/* .handle {
  background: rgb(204 204 204 / 40%);
  font-size: 10px;
  padding: 3px;
  cursor: move;
  z-index: 9;
  left: 0px;
} */

.color input,
.shapeColor input {
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0px;
  left: 25px;
}

.borderColor input {
  left: 175px;
}

.shapeColor input {
  left: 50px;
}

.fontName select {
  background-color: #ffffff;
  font-size: 10px;
  width: auto;
  left: 105px;
}

.pageImage {
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

/* .Landscape .item-card {
  height: 155px;
} */

.Landscape .qr-code-content {
  width: 15%;
}

.react-resizable .qr-code {
  width: 100%;
  height: 100%;
  padding: 5px;
  background-color: #fff;
}

.pagination {
  width: 52%;
  margin: auto;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  flex-wrap: nowrap !important;
}

.products-modal .modal-dialog,
.product-modal .modal-dialog,
.images-modal .modal-dialog {
  width: 85vw;
  max-width: 85vw;
}

.products-modal .pageProducts .notExist {
  display: none;
}

/* event */

.event-block a {
  color: #000;
}

.event-block img {
  width: 100px; /* Initial smaller size */
  height: auto;
  transform: scale(0.8);
  filter: grayscale(100%); /* Remove color */
  transition: transform 0.3s ease, filter 0.3s ease; /* Smooth transition */
  border-radius: 10px;
}

.event-block img:hover {
  transform: none; /* Enlarge on hover */
  filter: none; /* Apply color filter */
}

.event-header {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-top: 4.5rem;
  position: relative;
  height: 92vh;
  width: 100%;
}

.event-header-overlay {
  background-color: #ffffffd6;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}

.event-header-content {
  position: relative;
  z-index: 99;
}

.event-header-description {
  /* text-indent: 50px; */
  text-align: justify;
}

.event-logo {
  width: 150px;
  padding: 5px;
  height: 150px;
  object-fit: cover;
  /* border: 1px solid #000; */
}

.event-title-dates {
  background-color: #ffffffeb;
  position: absolute;
  text-align: start;
  padding: 10px;
  margin: auto;
  bottom: 20px;
  width: 40%;
  right: 0;
  left: 0;
}

.event-title {
  position: relative;
}

.event-dates,
.event-times {
  margin-top: 1.5rem;
}

.event-title::after {
  left: 0;
  right: 0;
  width: 100%;
  content: "";
  height: 5px;
  margin: auto;
  bottom: -15px;
  position: absolute;
  border-radius: 6px;
  background-color: #000;
}

.eventRow {
  width: 88%;
}

@media only screen and (max-width: 768px) {
  .item-details .qr-code {
    width: 25px;
    height: 25px;
  }

  .eventRow {
    width: 100%;
  }
  /* .page-style {
    width: unset !important;
    height: 100% !important;
  } */

  /* .row {
    width: 100% !important;
  } */

  .event-title-dates {
    width: 100%;
  }

  .event-header {
    background-size: contain;
    height: 28vh;
  }

  .product-modal .qr-code-image {
    width: 23%;
  }

  .product-modal .modal-dialog {
    max-width: none;
    width: auto;
  }

  .company-profile h2 {
    font-size: 14px;
  }

  .item-details p {
    /* height: 35%; */
    font-size: 9px;
    /* line-height: 9px; */
  }

  .item-details h5 {
    font-size: 6px;
  }

  .item-description {
    font-size: 6px;
    height: 25%;
  }

  .hide-link {
    display: none !important;
  }

  .hide-nav {
    bottom: 0;
    width: 100%;
    align-items: center;
    top: unset !important;
    justify-content: center;
    display: flex !important;
  }

  .hide-nav .navbar-nav-customize {
    width: 100%;
  }

  .hide-nav .navbar-nav-customize .nav-item {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .catalogPage .catalog-cover.Portrait {
    width: 100%;
  }

  .catalogPage .search-input {
    width: 50%;
  }

  .sin-product-verfiy-logos {
    right: 25%;
  }

  .turn-page {
    background-color: #eee;
    box-shadow: inset 1px 1px 10px 10px #0000002e;
  }

  .body-class.catalogPageDiv {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    height: 100vh;
    overflow: auto;
    padding-top: 2rem;
    position: absolute;
    align-items: center;
    padding-bottom: 2rem;
    justify-content: center;
  }

  .body-class.catalogPageDiv .no-overflow {
    overflow: hidden;
    position: absolute;
  }

  .body-class.homeDiv {
    position: relative;
  }

  .body-class.homeDiv .open_catalog_link {
    position: absolute;
    z-index: 999999;
    font-size: 11px;
    display: block;
    color: #000;
    left: 100%;
    top: 5%;
  }

  .body-class.homeDiv.LandscapeDiv .open_catalog_link {
    top: 12%;
  }

  .shelf-books:before,
  .shelf-books:after {
    background-image: url("../../public/assets/images/Wood-Pattern-4.jpg");
    border-radius: 10px;
    position: absolute;
    content: "";
    width: 18px;
    top: 15px;
    bottom: 0;
    left: 0%;
  }

  .shelf-books:after {
    right: 0%;
    left: unset;
  }

  .body-class {
    position: relative;
    height: 360px;
  }

  .col-md-2 {
    height: 360px;
  }

  .shelf-container {
    position: absolute;
    perspective: 800px;
    display: block;
    height: 115px;
    z-index: -1;
    width: 100%;
    left: 0%;
    top: 66%;
  }

  .shelf-card {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    transform-origin: bottom;
    transform: rotateX(60deg);
    /* background-color: #3498db; */
    transition: transform 0.5s;
    background-image: url("../../public/assets/images/Wood-Pattern-4.jpg");
  }

  .shelf-books .catalgos .catalog-cover.Landscape::before {
    width: 1030px;
    height: 272px;
    bottom: -11%;
    transform: rotateY(30deg);
  }

  .shelf-books .catalgos .catalog-cover.Portrait,
  .shelf-books .catalgos .catalog-cover.Portrait:hover {
    transform: translate(-20%, -25%) scale(0.35);
  }

  .shelf-books .catalgos {
    background-image: none;
  }

  .carousel-item {
    padding: 0;
  }

  .shelf-books .catalgos {
    top: 0.5rem;
  }

  .shelf-books .catalgos .catalog-cover:after {
    left: -13px;
    width: 93%;
  }

  /* .shelf-books:nth-child(3) {
    top: 7rem;
  } */

  .shelf-books .catalgos {
    /* height: 135px; */
    height: auto;
  }

  .shelf-books .catalgos .catalog-cover,
  .shelf-books .catalgos .catalog-cover:hover {
    transform: translate(-42%, -30.8%) scale(0.065);
  }

  .shelf-books
    .catalgos
    .catalog-cover
    .book-container
    .turn-page-wrapper.first-page {
    transform: unset;
  }

  .shelf-books {
    margin-top: 0px;
    height: auto;
    /* height: 115rem; */
  }

  /* .event-page .shelf-books {
    height: 91rem;
  } */

  .bordered-box {
    margin-top: 85px;
  }

  .marketing.allCatalog {
    height: 100vh;
    overflow: auto;
  }

  .marketing.home {
    height: 245rem !important;
  }

  .marketing.event {
    height: auto !important;
  }

  .catalog-control {
    top: -155px;
  }

  .about-icon {
    width: 25% !important;
    margin: 0 auto;
  }

  section {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .aggregator .all-catalogs {
    top: auto;
    left: 25%;
    right: 25%;
    bottom: 0;
    margin: auto;
  }

  .header-image {
    height: 395px;
  }

  .pagination {
    bottom: -244%;
    width: 64%;
  }
}

/* Default styles */
.center {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.book-container-customize {
  width: 1100px;
  height: 800px;
  /* margin: 0 auto; */
}

.book-page {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.book-page.turn-page.p3 {
  display: none;
}

/* confirm message */
.confirm-dialog-backdrop {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background: rgb(0 0 0 / 70%);
}

.confirm-dialog {
  background: white;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.confirm-dialog p {
  margin-bottom: 20px;
}

.confirm-dialog button {
  margin-right: 10px;
  padding: 8px 12px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.confirm-dialog button:last-child {
  background-color: #6c757d;
}
/*--------------------*/

/* Responsive styles */
@media only screen and (max-width: 768px) {
  .book-container-customize {
    width: 100%;
    height: auto;
  }

  .book-page {
    width: 100%;
    height: auto;
  }

  .shelf-books .catalgos .catalog-cover.Landscape {
    transform: translate(-26%, 0) scale(0.28) !important;
  }

  .shelf-books .catalgos .catalog-cover.Portrait {
    transform: translate(-22%, -25%) scale(0.35) !important;
  }

  .catalogPageDiv .catalog-cover .book-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
