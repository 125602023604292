/* 
- This Code Designed by Sinaha Team 2024
- This Portrait Catalog Cover Design (Template-1)
*/
@use "./layout/header";

.Portrait {
  &.temp-1 {
    .coverImage {
      justify-content: center;
      align-items: stretch;
      position: absolute;
      overflow: hidden;
      display: flex;
      margin: auto;
      height: 60%;
      opacity: 1;
      z-index: 0;
      bottom: 0;
      right: 0;
      left: 0;
      .overlay {
        background: linear-gradient(0deg, #000000, #00000080);
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
      }
    }

    .rectangle-center-inside {
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      display: flex;
      height: 85%;
      width: 85%;
    }

    .rectangle-center-company-logo {
      background-color: #fff;
      border-radius: 20px;
      position: relative;
      overflow: hidden;
      height: 80%;
      width: 80%;
      img {
        transform: rotate(316deg);
        object-fit: contain;
        position: absolute;
        margin: auto;
        width: 75%;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
      }
    }

    .year {
      position: absolute;
      margin-right: auto;
      margin-left: auto;
      z-index: 9999;
      width: 100%;
      bottom: 2%;
      right: 0;
      left: 0;
      p {
        font-size: 14px;
        margin: 0;
        color: #fff;
      }
      &::before {
        top: 50%;
        left: 10%;
        width: 30%;
        content: "";
        position: absolute;
        border: 1px solid #fff;
        transform: translateY(-50%);
      }
      &::after {
        top: 50%;
        width: 30%;
        right: 10%;
        content: "";
        position: absolute;
        border: 1px solid #fff;
        transform: translateY(-50%);
      }
    }

    .cover-qr-code {
      position: absolute;
      height: auto;
      width: 15%;
      z-index: 9;
      right: 2%;
      top: 2%;
    }

    .copyright-text {
      writing-mode: vertical-rl;
      text-orientation: mixed;
      letter-spacing: 2px;
      position: absolute;
      font-size: 0.5em;
      bottom: unset;
      z-index: 999;
      color: #fff;
      left: 1%;
      top: 13%;
    }

    .title {
      position: absolute;
      margin: auto;
      left: unset;
      color: #fff;
      z-index: 99;
      bottom: 10%;
      h2 {
        font-weight: 800;
        margin: 0;
      }
      h4 {
        letter-spacing: 7px;
        margin: 0;
      }
    }
    .coverImage {
      img {
        object-fit: cover;
        width: 100%;
      }
    }

    .rectangle-left {
      border-radius: 0px 20px 0 0;
      transform: rotate(45deg);
      filter: brightness(85%);
      position: absolute;
      height: 50%;
      left: -40%;
      width: 70%;
      z-index: 9;
      top: 7%;
    }

    .rectangle-right {
      transform: rotate(45deg);
      filter: brightness(85%);
      position: absolute;
      right: -50%;
      height: 50%;
      width: 70%;
      z-index: 9;
      top: 14%;
    }

    .rectangle-center {
      transform: rotate(45deg);
      background-color: #fff;
      justify-content: center;
      border-radius: 20px;
      align-items: center;
      position: absolute;
      margin-right: auto;
      margin-left: auto;
      display: flex;
      z-index: 99;
      height: 35%;
      width: 50%;
      right: 0;
      top: 15%;
      left: 0;
    }

    .company-name {
      justify-content: center;
      align-items: center;
      position: absolute;
      display: flex;
      margin: auto;
      z-index: 99;
      width: 75%;
      top: 60%;
      right: 0;
      left: 0;
      h4 {
        font-weight: 800;
        font-size: 25px;
        color: #fff;
        margin: 0;
      }
    }

    .title {
      right: 0;
      left: 0;
      h2 {
        font-size: 4em;
      }
      h4 {
        font-size: 1.8em;
      }
    }

    .turn-ar {
      .title {
        h2 {
          font-size: 3em;
        }
        h4 {
          letter-spacing: 0;
          font-size: 14px;
        }
      }
    }

    @media only screen and (max-width: 768px) {
      .rectangle-center {
        width: 45%;
      }

      .company-name {
        h4 {
          font-size: 14px;
        }
      }

      .title {
        h2 {
          font-size: 2em;
        }
        h4 {
          font-size: 0.5em;
        }
      }

      .turn-ar {
        .title {
          h2 {
            font-size: 2em;
          }
        }
      }

      .copyright-text {
        font-size: 0.4em;
      }

      .year {
        p {
          font-size: 10px;
        }
      }
    }
  }
}

.Landscape {
  &.temp-1 {
    .coverImage {
      justify-content: center;
      align-items: stretch;
      position: absolute;
      overflow: hidden;
      display: flex;
      margin: auto;
      height: 60%;
      opacity: 1;
      z-index: 0;
      bottom: 0;
      right: 0;
      left: 0;
      .overlay {
        background: linear-gradient(0deg, #000000, #00000080);
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
      }
    }

    .rectangle-center-inside {
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      display: flex;
      height: 85%;
      width: 85%;
    }

    .rectangle-center-company-logo {
      background-color: #fff;
      object-fit: contain;
      border-radius: 20px;
      position: relative;
      overflow: hidden;
      height: 80%;
      width: 80%;
      img {
        transform: rotate(316deg);
        position: absolute;
        margin: auto;
        width: 75%;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
      }
    }

    .year {
      position: absolute;
      margin-right: auto;
      margin-left: auto;
      z-index: 9999;
      width: 100%;
      bottom: 2%;
      right: 0;
      left: 0;
      p {
        font-size: 14px;
        margin: 0;
        color: #fff;
      }
      &::before {
        top: 50%;
        left: 10%;
        width: 30%;
        content: "";
        position: absolute;
        border: 1px solid #fff;
        transform: translateY(-50%);
      }
      &::after {
        top: 50%;
        width: 30%;
        right: 10%;
        content: "";
        position: absolute;
        border: 1px solid #fff;
        transform: translateY(-50%);
      }
    }

    .cover-qr-code {
      position: absolute;
      height: auto;
      width: 10%;
      z-index: 9;
      right: 2%;
      top: 2%;
    }

    .copyright-text {
      writing-mode: vertical-rl;
      text-orientation: mixed;
      letter-spacing: 2px;
      position: absolute;
      font-size: 0.5em;
      bottom: unset;
      z-index: 999;
      color: #fff;
      left: 1%;
      top: 13%;
    }

    .title {
      position: absolute;
      margin: auto;
      left: unset;
      color: #fff;
      z-index: 99;
      bottom: 10%;
      h2 {
        font-weight: 800;
        margin: 0;
      }
      h4 {
        letter-spacing: 7px;
        margin: 0;
      }
    }
    .rectangle-left {
      border-radius: 0px 20px 0 0;
      transform: rotate(45deg);
      filter: brightness(85%);
      position: absolute;
      height: 100%;
      left: -40%;
      width: 65%;
      z-index: 9;
      top: -25%;
    }

    .rectangle-center {
      transform: rotate(45deg);
      background-color: #fff;
      justify-content: center;
      border-radius: 20px;
      align-items: center;
      position: absolute;
      margin-right: auto;
      margin-left: auto;
      display: flex;
      z-index: 99;
      height: 55%;
      width: 36%;
      top: 9%;
      left: 15%;
    }

    .rectangle-right {
      position: absolute;
      right: -22%;
      top: 3%;
      z-index: 9;
      --r: 75px;
      width: 85%;
      aspect-ratio: 1;
      object-fit: cover;
      margin: calc(tan(22.5deg) * var(--r));
      clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%) margin-box;
      --_g: /calc(2 * var(--r)) calc(2 * var(--r)) radial-gradient(#000 70%, #0000
            72%);
      --_s: calc(100% - (1 - tan(22.5deg)) * var(--r));
      mask: conic-gradient(#000 0 0) no-repeat 50% / var(--_s) var(--_s),
        top var(--_g) no-repeat space, left var(--_g) space no-repeat;
    }

    .company-name {
      justify-content: center;
      align-items: center;
      position: absolute;
      display: flex;
      margin: auto;
      z-index: 99;
      width: 50%;
      top: 57%;
      right: 3%;
      h4 {
        font-weight: 800;
        font-size: 35px;
        color: #fff;
        margin: 0;
      }
    }

    .title {
      bottom: 10%;
      left: 9%;
      h2 {
        font-size: 2.5em;
      }
      h4 {
        font-size: 0.8em;
      }
    }

    .turn-ar {
      .title {
        left: 5%;
        h2 {
          font-size: 2em;
        }
        h4 {
          letter-spacing: 0;
          font-size: 14px;
        }
      }
    }
  }
}

.homeDiv,
.allCatalogDiv {
  .Portrait {
    &.temp-1 {
      @media only screen and (max-width: 768px) {
        .company-name {
          h4 {
            font-size: 25px;
          }
        }
      }
    }
  }
}

.homeDiv,
.allCatalogDiv {
  .Landscape {
    &.temp-1 {
      @media only screen and (max-width: 768px) {
        .company-name {
          h4 {
            font-size: 25px;
          }
        }
      }
    }
  }
}

/* 
- This Code Designed by Sinaha Team 2024
- This Portrait Catalog Cover Design (Template-2)
*/

.Portrait {
  &.temp-2 {
    .year {
      position: absolute;
      margin-right: auto;
      margin-left: auto;
      z-index: 9999;
      width: 100%;
      bottom: 2%;
      right: 0;
      left: 0;
      p {
        font-size: 14px;
        color: #fff;
        margin: 0;
      }
      &::before {
        transform: translateY(-50%);
        border: 1px solid #fff;
        position: absolute;
        content: "";
        width: 30%;
        left: 10%;
        top: 50%;
      }
      &::after {
        transform: translateY(-50%);
        border: 1px solid #fff;
        position: absolute;
        content: "";
        width: 30%;
        right: 10%;
        top: 50%;
      }
    }

    .copyright-text {
      writing-mode: vertical-rl;
      text-orientation: mixed;
      letter-spacing: 2px;
      position: absolute;
      font-size: 10px;
      z-index: 999;
      left: 1%;
    }

    .company-name {
      position: absolute;
      margin-right: auto;
      text-align: center;
      margin-left: auto;
      z-index: 999999;
      bottom: 34%;
      width: 65%;
      right: 0;
      left: 0;
      h4 {
        font-size: 1.5rem;
        font-weight: 800;
        margin-bottom: 0;
        color: #fff;
        margin-top: 0;
      }
    }

    .cover-qr-code {
      position: absolute;
      height: auto;
      right: 2%;
      top: 2%;
    }

    .company-logo {
      position: absolute;
      height: auto;
      z-index: 999;
    }

    .title {
      position: absolute;
      text-align: center;
      z-index: 99999;
      color: #fff;
      margin: auto;
      bottom: 10%;
      right: 0;
      left: 0;
      h2 {
        font-weight: 800;
        margin: 0;
      }
      h4 {
        letter-spacing: 7px;
        margin: 0;
      }
    }

    .cropped-image {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    .turn-ar {
      .title {
        h4 {
          letter-spacing: 0;
          font-size: 14px;
        }
      }
    }
    .cover-qr-code {
      width: 20%;
    }

    .company-logo {
      width: 40%;
      left: 4%;
      top: 2%;
    }

    .copyright-text {
      top: 30%;
    }

    .title {
      h2 {
        font-size: 3em;
      }
      h4 {
        font-size: 1.1em;
      }
    }

    .curved-corner-topright {
      transform: rotate(270deg);
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 80%;
      z-index: 99;
      top: 18%;
      right: 0;
      left: 8%;
      &::before {
        box-shadow: 242px -106px 0 0 #fff;
        position: absolute;
        border-radius: 50%;
        display: block;
        height: 200%;
        width: 200%;
        content: "";
        right: 0;
        top: 0;
      }
    }

    .cover-background {
      position: absolute;
      height: 76%;
      width: 100%;
      padding: 0;
      margin: 0;
      top: 24%;
      right: 0;
      left: 0;
    }

    .circle-cover {
      border-radius: 95% 0 0 0;
      position: absolute;
      z-index: 9999;
      width: 100%;
      height: 76%;
      opacity: 1;
      bottom: 0;
      right: 0;
    }

    .circle-cover-2 {
      border-radius: 100% 0 0 0;
      position: absolute;
      z-index: 999;
      width: 100%;
      height: 76%;
      bottom: 5%;
      right: 0;
    }

    @media only screen and (max-width: 768px) {
      .curved-corner-topright {
        left: 2%;
        top: 22%;
      }

      .title {
        h2 {
          font-size: 2em;
        }
        h4 {
          font-size: 0.4em;
        }
      }

      .turn-ar {
        .title {
          h4 {
            font-size: 14px;
          }
        }
      }

      .company-name {
        h4 {
          font-size: 14px;
        }
      }

      .copyright-text {
        top: 25%;
        font-size: 0.3em;
      }

      .year {
        p {
          font-size: 10px;
        }
      }
    }
  }
}

.Landscape {
  &.temp-2 {
    .year {
      position: absolute;
      margin-right: auto;
      margin-left: auto;
      z-index: 9999;
      width: 100%;
      bottom: 2%;
      right: 0;
      left: 0;
      p {
        font-size: 14px;
        color: #fff;
        margin: 0;
      }
      &::before {
        transform: translateY(-50%);
        border: 1px solid #fff;
        position: absolute;
        content: "";
        width: 30%;
        left: 10%;
        top: 50%;
      }
      &::after {
        transform: translateY(-50%);
        border: 1px solid #fff;
        position: absolute;
        content: "";
        width: 30%;
        right: 10%;
        top: 50%;
      }
    }

    .copyright-text {
      writing-mode: vertical-rl;
      text-orientation: mixed;
      letter-spacing: 2px;
      position: absolute;
      font-size: 10px;
      z-index: 999;
      left: 1%;
    }

    .company-name {
      position: absolute;
      margin-right: auto;
      text-align: center;
      margin-left: auto;
      z-index: 999999;
      bottom: 34%;
      width: 65%;
      right: 0;
      left: 0;
      h4 {
        font-size: 1.5rem;
        font-weight: 800;
        margin-bottom: 0;
        color: #fff;
        margin-top: 0;
      }
    }

    .cover-qr-code {
      position: absolute;
      height: auto;
      right: 2%;
      top: 2%;
    }

    .company-logo {
      position: absolute;
      height: auto;
      z-index: 999;
    }

    .title {
      position: absolute;
      text-align: center;
      z-index: 99999;
      color: #fff;
      margin: auto;
      bottom: 10%;
      right: 0;
      left: 0;
      h2 {
        font-weight: 800;
        margin: 0;
      }
      h4 {
        letter-spacing: 7px;
        margin: 0;
      }
    }

    .cropped-image {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    .turn-ar {
      .title {
        h4 {
          letter-spacing: 0;
          font-size: 14px;
        }
      }
    }
    .cover-qr-code {
      width: 10%;
    }

    .company-logo {
      width: 22%;
      left: 8%;
      top: 5%;
    }

    .curved-corner-topright {
      height: 165%;
      left: 30%;
      overflow: hidden;
      position: relative;
      top: -18%;
      transform: rotate(270deg);
      width: 50%;
      z-index: 99;
      &::before {
        border-radius: 50%;
        box-shadow: 190px -100px 0 0 #fff;
        content: "";
        display: block;
        height: 200%;
        position: absolute;
        right: 0;
        top: 0;
        width: 200%;
      }
    }

    .cover-background {
      position: absolute;
      height: auto;
      width: 100%;
      padding: 0;
      margin: 0;
      top: 28%;
      left: 0;
    }

    .company-name {
      width: 50%;
    }

    .copyright-text {
      top: 20%;
    }

    .title {
      h2 {
        font-size: 2.5em;
      }
      h4 {
        font-size: 0.8em;
      }
    }

    .company-name {
      h4 {
        font-size: 1.2rem;
      }
    }

    .circle-cover {
      border-radius: 95% 0 0 0;
      position: absolute;
      z-index: 9999;
      height: 72%;
      width: 100%;
      opacity: 1;
      bottom: 0;
      right: 0;
    }

    .circle-cover-2 {
      border-radius: 100% 0 0 0;
      position: absolute;
      z-index: 999;
      height: 74%;
      width: 100%;
      bottom: 5%;
      right: 0;
    }

    @media only screen and (max-width: 768px) {
      .curved-corner-topright {
        height: 175%;
        left: 23%;
        &::before {
          box-shadow: 200px -100px 0 0 #fff;
        }
      }
    }
  }
}

.homeDiv,
.allCatalogDiv {
  .Portrait {
    &.temp-2 {
      @media only screen and (max-width: 768px) {
        .company-name {
          h4 {
            font-size: 25px;
          }
        }
        .curved-corner-topright {
          left: 8%;
          top: 18%;
        }
      }
    }
  }
}

.homeDiv,
.allCatalogDiv {
  .Landscape {
    &.temp-2 {
      @media only screen and (max-width: 768px) {
        .company-name {
          h4 {
            font-size: 25px;
          }
        }
      }
    }
  }
}

/* 
Perview Style (when create catalog)
*/

.Portrait {
  &.temp-1 {
    &.perview {
      .rectangle-center {
        border-radius: 8px;
      }
      .rectangle-center-inside {
        border-radius: 4px;
      }
      .rectangle-center-company-logo {
        border-radius: 4px;
      }
      .company-name {
        h4 {
          font-size: 8px;
        }
      }
      .title {
        h2 {
          font-size: 1em;
        }
        h4 {
          font-size: 0.1rem;
          letter-spacing: 4px;
        }
      }
      .year {
        p {
          font-size: 7px;
        }
      }
      .copyright-text {
        letter-spacing: 1px;
        font-size: 0.1em;
      }
    }
  }
}

.Landscape {
  &.temp-1 {
    &.perview {
      .rectangle-center {
        border-radius: 8px;
      }
      .rectangle-center-inside {
        border-radius: 4px;
      }
      .rectangle-right {
        right: -19%;
        --r: 10px;
      }
      .rectangle-center-company-logo {
        border-radius: 4px;
      }
      .company-name {
        h4 {
          font-size: 8px;
        }
      }
      .title {
        h2 {
          font-size: 0.5em;
        }
        h4 {
          font-size: 0.1rem;
          letter-spacing: 1px;
        }
      }
      .year {
        p {
          font-size: 3px;
        }
      }
      .copyright-text {
        letter-spacing: 0.5px;
        font-size: 0.1em;
      }
    }
  }
}

.Portrait {
  &.temp-2 {
    &.perview {
      .curved-corner-topright {
        background-color: #fff;
        left: 5%;
      }
      .rectangle-center {
        border-radius: 8px;
      }
      .rectangle-center-inside {
        border-radius: 4px;
      }
      .rectangle-center-company-logo {
        border-radius: 4px;
      }
      .company-name {
        h4 {
          font-size: 6px;
        }
      }
      .title {
        h2 {
          font-size: 0.7em;
        }
        h4 {
          font-size: 0.1rem;
          letter-spacing: 2.5px;
        }
      }
      .year {
        p {
          font-size: 7px;
        }
      }
      .copyright-text {
        letter-spacing: 1px;
        font-size: 0.1em;
      }
    }
  }
}

.Landscape {
  &.temp-2 {
    &.perview {
      .curved-corner-topright {
        background-color: #fff;
        left: 5%;
      }
      .rectangle-center {
        border-radius: 8px;
      }
      .rectangle-center-inside {
        border-radius: 4px;
      }
      .rectangle-center-company-logo {
        border-radius: 4px;
      }
      .company-name {
        h4 {
          font-size: 4px;
        }
      }
      .title {
        h2 {
          font-size: 0.5em;
        }
        h4 {
          font-size: 0.1rem;
          letter-spacing: 1.5px;
        }
      }
      .year {
        p {
          font-size: 4px;
        }
      }
      .copyright-text {
        letter-spacing: 1px;
        font-size: 0.1em;
      }
    }
  }
}

.customized-catalog{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  
  .year {
    p {
      font-size: 14px;
      margin: 0;
    }
    &::before {
      transform: translateY(-50%);
      border: 1px solid #000;
      position: absolute;
      content: "";
      width: 30%;
      left: 10%;
      top: 50%;
    }
    &::after {
      transform: translateY(-50%);
      border: 1px solid #000;
      position: absolute;
      content: "";
      width: 30%;
      right: 10%;
      top: 50%;
    }
  }
}
